.CategoryPage {
  .Subcategory {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 20px 30px;
    margin-bottom: 20px;
    & > .header {
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 20px;
    }
  }
}