.HomePage {
  display: flex;
  .searchbox {
    flex: 0 0 388px;
    padding: 30px 25px;
    margin: 15px 20px 0 15px;
    background: #fff;
    box-shadow: 0 0 3px 3px #00000009;
    align-self: flex-start;
    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 13px;
      .MuiButton-root {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    .MuiButton-root {
      padding-top: 3px;
      padding-bottom: 3px;
    }
    &.searchType-INDICATOR .Filters .categories-scroll { max-height: calc(100vh - 308px); }
    &.searchType-REPORT .Filters .categories-scroll { max-height: calc(100vh - 415px); }
    &.searchType-ORG .Filters .categories-scroll { max-height: calc(100vh - 323px); }
    &.searchType-ALL .Filters .categories-scroll { max-height: calc(100vh - 279px); }
  }
  .footer {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
  }
}

.NoResults {
  font-size: 19px;
  font-weight: 300;
  padding: 25px 15px;
}
