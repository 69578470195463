@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

:root {
  font-size: 15px;
  --primary: rgb(241,101,37);
  --secondary: rgb(21, 55, 80);
  --tertiary: rgb(0, 153, 80);
  --red: #ba0000;
  --green: #1ab91a;
  --white: #ffffff;
  --black: #333333;
  --lightgray: #e8ecf1;
  --gray: #8798a8;
  --darkgray: #526475;
  --text-dark: #202c4a;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  color: --var(text-dark);
  background-color: #fdfdfd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, select {
  color: var(--text-dark);
}

a {
  color: var(--text-dark);
}

.container {
  padding: 0 20px;
}

.labelvalue {
  display: grid;
  grid-template-columns: max-content 1fr;
  & > span:nth-child(1) {
    color: #888;
    margin-right: 15px;
  }
  .links {
    display: flex;
    flex-direction: column;
    & > * + * {
      margin-top: 5px;
    }
  }
}
.labelvalue + .labelvalue {
  margin-top: 15px;
}

.pt50 { padding-top: 50px; }
.pt100 { padding-top: 100px; }
.pb50 { padding-bottom: 50px; }
.pb100 { padding-bottom: 100px; }

body {

  .MuiInputLabel-root {
    transform: translate(14px, 6px) scale(1);
    &[data-shrink="true"] {
      transform: translate(14px, -7px) scale(0.75);
    }
  }

  .MuiInputBase-inputSizeSmall {
    line-height: 1;
    height: 1.1em;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .MuiAutocomplete-listbox {
    .MuiAutocomplete-option {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  .MuiTooltip-tooltip {
    font-size: 12px;
  }
}

.disabled-text {
  color: #777;
}

.fade-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(#fff0 0%, #fff5 20%, #fffc 40%, #fff 100%);
  pointer-events: none;
}