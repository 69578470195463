@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
:root{font-size:15px;--primary: rgb(241,101,37);--secondary: rgb(21, 55, 80);--tertiary: rgb(0, 153, 80);--red: #ba0000;--green: #1ab91a;--white: #ffffff;--black: #333333;--lightgray: #e8ecf1;--gray: #8798a8;--darkgray: #526475;--text-dark: #202c4a}*{box-sizing:border-box}body{margin:0;font-family:Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-size:15px;color:--var(text-dark);background-color:#fdfdfd}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}input,select{color:#202c4a;color:var(--text-dark)}a{color:#202c4a;color:var(--text-dark)}.container{padding:0 20px}.labelvalue{display:grid;grid-template-columns:-webkit-max-content 1fr;grid-template-columns:max-content 1fr}.labelvalue>span:nth-child(1){color:#888;margin-right:15px}.labelvalue .links{display:flex;flex-direction:column}.labelvalue .links>*+*{margin-top:5px}.labelvalue+.labelvalue{margin-top:15px}.pt50{padding-top:50px}.pt100{padding-top:100px}.pb50{padding-bottom:50px}.pb100{padding-bottom:100px}body .MuiInputLabel-root{transform:translate(14px, 6px) scale(1)}body .MuiInputLabel-root[data-shrink="true"]{transform:translate(14px, -7px) scale(0.75)}body .MuiInputBase-inputSizeSmall{line-height:1;height:1.1em;padding-top:7px;padding-bottom:7px}body .MuiAutocomplete-listbox .MuiAutocomplete-option{padding-top:3px;padding-bottom:3px}body .MuiTooltip-tooltip{font-size:12px}.disabled-text{color:#777}.fade-bar{position:absolute;bottom:0;left:0;right:0;height:40px;background:linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,0.333333) 20%, rgba(255,255,255,0.8) 40%, #fff 100%);pointer-events:none}

.ReportPage h2{margin:0;padding:20px 0}.ReportDetail a{display:flex;align-items:center}.ReportDetail a svg{margin-left:7px}.ReportDetail .source-links{display:flex;align-items:flex-start}.ReportDetail .source-links a{max-width:600px}.ReportDetail .source-links a+a{margin-left:20px;font-size:90%}.ReportDetail .Cards{margin-top:30px}.ReportDetail .Cards .header{font-size:18px}

.ContainerSpinner{padding:20px}

.TitleBar{display:flex;align-items:center;color:var(--primary)}.TitleBar button{margin-right:5px}

.Cards{position:relative}.Cards:not(.mosaic){display:grid;grid-template-columns:repeat(auto-fill, 300px);align-items:flex-start;grid-gap:20px;gap:20px}.Cards.mosaic>.MuiCard-root{position:absolute;top:0;left:0;width:300px}.Cards .MuiCardContent-root{padding:0;position:relative}.Cards .MuiCardContent-root:last-child{padding-bottom:0}.Cards .header{display:flex;font-size:20px;font-weight:300;align-items:center;padding:16px;border-bottom:1px solid #eee}.Cards .header svg{margin-right:10px}.Cards .links{display:flex;flex-direction:column;max-height:400px;overflow-y:auto;-webkit-overflow-scrolling:touch;padding:16px 16px 40px}.Cards .links a+a{margin-top:10px}.Cards .links a{text-decoration:none}.Cards .links a:hover,.Cards .links a:active{text-decoration:underline}.Cards .links a span{order:1}.Cards .links a svg{order:0;margin-left:0px;margin-right:10px}

.OrgPage a{display:inline-flex;align-items:center}.OrgPage a svg{margin-left:10px}

.HomePage{display:flex}.HomePage .searchbox{flex:0 0 388px;padding:30px 25px;margin:15px 20px 0 15px;background:#fff;box-shadow:0 0 3px 3px #00000009;align-self:flex-start}.HomePage .searchbox .header{display:flex;align-items:center;justify-content:center;margin-bottom:13px}.HomePage .searchbox .header .MuiButton-root{padding-left:8px;padding-right:8px}.HomePage .searchbox .MuiButton-root{padding-top:3px;padding-bottom:3px}.HomePage .searchbox.searchType-INDICATOR .Filters .categories-scroll{max-height:calc(100vh - 308px)}.HomePage .searchbox.searchType-REPORT .Filters .categories-scroll{max-height:calc(100vh - 415px)}.HomePage .searchbox.searchType-ORG .Filters .categories-scroll{max-height:calc(100vh - 323px)}.HomePage .searchbox.searchType-ALL .Filters .categories-scroll{max-height:calc(100vh - 279px)}.HomePage .footer{margin-top:20px;display:grid;grid-gap:20px;gap:20px;grid-template-columns:1fr 1fr}.NoResults{font-size:19px;font-weight:300;padding:25px 15px}

.Filters{margin-top:10px}.Filters .filter-name{font-weight:500;padding:10px 0 5px;color:#999;font-size:80%}.Filters .categories .MuiFormControlLabel-label{display:flex;align-items:center;padding-left:5px}.Filters .categories-scroll{max-height:calc(100vh - 436px);min-height:100px;overflow-y:scroll;border:1px solid #eee;border-radius:4px;padding:5px 10px 10px}.Filters .category-name{margin-right:7px}.Filters .category>.MuiFormControlLabel-root{margin-left:0}.Filters .MuiFormControlLabel-label{font-size:0.95rem}.Filters .subcategories{padding-left:30px}.Filters .autocompletes{padding:5px 0;display:flex;flex-wrap:wrap}.Filters .autocompletes>.MuiAutocomplete-root+.MuiAutocomplete-root{margin-top:15px}.Filters .MuiAccordionSummary-root{min-height:40px}.Filters .MuiAccordionSummary-root.Mui-expanded{min-height:44px}.Filters .MuiAccordionSummary-root .MuiAccordionSummary-content{margin:8px 0}.Filters .MuiAccordionSummary-root .MuiIconButton-root{padding:8px}

.SearchResultsWrapper{flex:1 1 80%;position:relative;height:100vh}.SearchResultsWrapper .fade-bar{height:50px}.SearchResults{display:flex;flex-direction:column;overflow-y:auto;-webkit-overflow-scrolling:touch;height:100%;padding:20px 20px 20px 0}.SearchResults:not(.all)>*+*{margin-top:15px}.SearchResults>.header{font-size:19px;font-weight:300;padding:10px 0}.SearchResults.all .SearchResult:hover,.SearchResults.all .SearchResult:active{border-color:#f4f4f4}.SearchResults .group .items{display:flex;flex-direction:column;padding-bottom:25px}.SearchResults .group .items>*+*{margin-top:15px}.SearchResults .cname{font-size:16px;text-transform:uppercase;font-weight:500;letter-spacing:1px;color:#888;padding:15px 0 10px;margin-bottom:10px;background-color:#fdfdfd;position:-webkit-sticky;position:sticky;top:-20px}.SearchResults .subgroup{background-color:#fff;padding:15px;border:1px solid #f4f4f4;border-radius:5px}.SearchResults .subgroup .sname{font-size:18px;font-weight:300;padding:5px 0 0}.SearchResults .subgroup .items{padding:20px 0 10px}.SearchResults .subgroup .SearchResult{text-decoration:underline;border:none;border-radius:0;padding:0;font-size:14px;font-weight:400}.SearchResults .subgroup .SearchResult+.SearchResult{margin-top:10px}.SearchResults .subgroup+.subgroup{margin-top:20px}.SearchResult{color:var(--secondary);text-decoration:none;border:1px solid #f4f4f4;border-radius:5px;padding:15px;background:#fff;font-size:18px;font-weight:300}.SearchResult:hover{border-color:rgba(241,101,37,0.4)}.SearchResult:active{border-color:rgba(241,101,37,0.8)}.SearchResult .MuiChip-root{max-width:100%}.CombinedListItem{font-size:15px;font-weight:400}.CombinedListItem .header{font-size:18px;font-weight:300;padding:5px 0 30px 0}.CombinedListItem .icon-label{display:flex;align-items:center}.CombinedListItem .icon-label svg{margin-right:8px}.CombinedListItem .Fields .labelvalue{grid-template-columns:150px 1fr}

.IndicatorPage a{display:inline-flex;align-items:center}.IndicatorPage a svg{margin-left:10px}.IndicatorPage a.small{margin-left:20px;font-size:90%}.IndicatorPage a.small svg{margin-left:5px}.IndicatorPage .Cards{margin-top:30px}.IndicatorPage .Cards .header{font-size:18px}

.Bubble{font-size:13px;margin-bottom:20px;color:#777;padding:8px;border:1px solid #dadae5;border-radius:7px;position:relative;background-color:#fafaff}.Bubble svg{position:absolute;background:#fff;top:-8px;left:0;transform:translateX(-7px)}

.Toast{position:fixed;top:20px;left:50%;transform:translateX(-50%);background:#b3f1c6;padding:10px 20px;border-radius:5px;box-shadow:0 0 15px -10px #0008}

.Menu{position:fixed !important;top:10px;right:20px}.PasteLinkModal{padding:30px 40px;display:flex;flex-direction:column;align-items:center}.PasteLinkModal .MuiButton-root{margin-top:10px;padding:4px ​16px}@media screen and (min-width: 900px){.PasteLinkModal .MuiFormControl-root{width:500px}}

.CategoryPage .Subcategory{border:1px solid #eee;border-radius:5px;padding:20px 30px;margin-bottom:20px}.CategoryPage .Subcategory>.header{font-size:18px;font-weight:300;margin-bottom:20px}

.SubcategoryCards{position:relative}

